"use client";

import {
  type SearchMonitorReq,
  type SearchMonitorResponse,
} from "@/app/api/list/monitor/search/route";
import { UpdateMonitorDialog } from "@/components/functional/create-monitors-dialog";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { type AccountsMonitor, MonitorType, type List } from "@prisma/client";
import { Crosshair2Icon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";

export const MonitorControlIcon = ({ list }: { list: List }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [currentMonitor, setCurrentMonitor] = useState<
    AccountsMonitor | undefined
  >(undefined);

  useEffect(() => {
    void fetch("/api/list/monitor/search", {
      method: "POST",
      body: JSON.stringify({ listId: list.id } as SearchMonitorReq),
    })
      .then((res) => res.json())
      .then((data: SearchMonitorResponse) => {
        setIsLoading(false);
        if (data.monitor) {
          setCurrentMonitor(data.monitor);
        }
      });
  }, [list.id]);

  if (isLoading) return <Skeleton className="w-6 h-6" />;

  return (
    <>
      <Button size={"icon"} variant={"ghost"} onMouseDown={() => setOpen(true)}>
        <Crosshair2Icon
          className={
            currentMonitor !== undefined
              ? "text-yellow-500 hover:text-yellow-600 transition-colors"
              : "text-gray-400 hover:text-gray-500 transition-colors"
          }
        />
      </Button>
      <UpdateMonitorDialog
        initialListId={list.id}
        initialData={currentMonitor}
        open={open}
        onCreate={() => {
          window?.location.reload();
        }}
        setOpen={setOpen}
        availableMonitorTypes={[MonitorType.RecentNews]}
      />
    </>
  );
};
