"use client";

import { Separator } from "@/components/ui/separator";
import { Label } from "@/components/ui/label";
import { PlanType, type Subscriptions } from "@prisma/client";
import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { NavBlockerLink } from "@/components/functional/nav-blocker-link";
import { useRouter } from "next/navigation";
import { ManageSubscriptionButton } from "@/components/functional/upgrade-subscription-button";
import { addOrdinalSuffix } from "@/lib/utils";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

const creditCosts = {
  "AI Research / Enrichments": 1,
  "Contact Fetch (Per Contact)": 1,
  "Email/Sequence Generation": 1,
  "Email Enrichment": 2,
  "Mobile Phone Enrichment": 10,
};

export function CreditsInfoDialog({
  subscription,
  showUsageLog,
  children,
}: {
  subscription: Subscriptions;
  showUsageLog: boolean;
  children: React.ReactNode;
}) {
  const [open, setOpen] = useState(false);

  return (
    <Popover onOpenChange={setOpen} open={open}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent className="p-0 w-fit mt-sm" align="center">
        <header className="flex flex-col space-y-sm px-2xl pt-xl pb-sm text-center sm:text-left w-fit">
          <Label className="text-base font-[525] not-italic leading-[140%] whitespace-nowrap">
            LavaReach Credits Remaining:{" "}
            <strong>
              {subscription.maxCredits -
                subscription.creditsUsed +
                subscription.oneTimeCredits}
            </strong>
          </Label>
          {subscription.maxCredits > 0 &&
            subscription.planType === PlanType.Monthly && (
              <Label className="text-text-secondary">
                <strong className="underline">{subscription.maxCredits}</strong>{" "}
                credits will be added on the{" "}
                <strong className="underline">
                  {addOrdinalSuffix(subscription.renewDate)}
                </strong>{" "}
                day of each month.
              </Label>
            )}
          {subscription.planType === PlanType.NotPaid && (
            <Label className="text-text-secondary">No paid subscription.</Label>
          )}
        </header>
        <Separator />
        <main className="flex flex-row flex-grow min-h-0 px-2xl py-xl">
          <div className="flex flex-col w-full gap-sm">
            <Label className="text-sm">Credit Cost Overview:</Label>
            <div className="grid grid-cols-3 rounded-md border">
              {Object.entries(creditCosts).map(([key, value], index) => (
                <React.Fragment key={key}>
                  <Label
                    key={key + "-key"}
                    className="col-span-2 text-center py-xs"
                  >
                    {key}
                  </Label>
                  <div className="flex">
                    <Separator orientation="vertical" />
                    <Label
                      key={key + "-value"}
                      className="text-text-secondary text-center flex-grow py-xs"
                    >
                      {value} Credits
                    </Label>
                  </div>
                  {index < Object.keys(creditCosts).length - 1 && (
                    <Separator className="col-span-3" />
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </main>
        <Separator />
        <footer className="flex items-center gap-lg sm:flex-row justify-end sm:rounded-b-lg px-2xl py-lg">
          {showUsageLog && (
            <Button>
              <NavBlockerLink href={"/dashboard/credits-info"}>
                Credit Usage Log
              </NavBlockerLink>
            </Button>
          )}
        </footer>
      </PopoverContent>
    </Popover>
  );
}

export function SimpleCreditsInfoDialog({
  subscription,
  children,
}: {
  subscription: Subscriptions;
  children: React.ReactNode;
}) {
  const [open, setOpen] = useState(false);
  const router = useRouter();

  return (
    <Popover onOpenChange={setOpen} open={open}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent className="p-0 w-fit mt-sm" align="center">
        <header className="flex flex-col space-y-sm px-2xl pt-xl pb-sm text-center sm:text-left w-fit">
          <Label className="text-base font-[525] not-italic leading-[140%] whitespace-nowrap">
            LavaReach Credits Remaining:{" "}
            <strong>
              {subscription.maxCredits -
                subscription.creditsUsed +
                subscription.oneTimeCredits}
            </strong>
          </Label>
        </header>
        <main className="flex flex-row flex-grow min-h-0 px-2xl pb-lg">
          {subscription.maxCredits > 0 &&
            subscription.planType === PlanType.Monthly && (
              <Label className="text-text-secondary">
                <strong className="underline">{subscription.maxCredits}</strong>{" "}
                credits will be added on the{" "}
                <strong className="underline">
                  {addOrdinalSuffix(subscription.renewDate)}
                </strong>{" "}
                day of each month.
              </Label>
            )}
          {subscription.planType === PlanType.NotPaid && (
            <Label className="text-text-secondary">No paid subscription.</Label>
          )}
        </main>
        <Separator />
        <footer className="flex items-center gap-lg sm:flex-row justify-between sm:rounded-b-lg px-2xl py-lg">
          <Button
            variant="outline"
            onMouseDown={() => {
              router.push("/dashboard/credits-info");
              setOpen(false);
            }}
          >
            Credit Usage Log
          </Button>
          {subscription.stripeSubscriptionId != null ||
          subscription.planType === PlanType.Monthly ? (
            <Button
              onMouseDown={() => {
                router.push("/dashboard/settings/billing");
                setOpen(false);
              }}
            >
              Manage Subscription
            </Button>
          ) : (
            <ManageSubscriptionButton
              variant={"default"}
              subscription={subscription}
            />
          )}
        </footer>
      </PopoverContent>
    </Popover>
  );
}
