"use client";

import { type EditListSchemaType } from "@/app/api/list/details/[slug]/route";
import PenSquareIcon from "@/components/icons/pen-square-icon";
import { Button } from "@/components/ui/button";
import {
  CompactDialogContent,
  CompactDialogFooter,
  CompactDialogHeader,
  Dialog,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { LoadingIcon } from "@/components/ui/loading-icon";
import { Separator } from "@/components/ui/separator";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";
import { type List } from "@prisma/client";
import { useState } from "react";

export function EditListNameButton({
  list,
  setListName,
}: {
  list: List;
  setListName?: (name: string) => void;
}) {
  const [name, setName] = useState(list.name);
  const [isEditing, setIsEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const isDirty = list.name !== name;

  function handleEdit() {
    if (isEditing) return;
    setIsEditing(true);

    void fetch(`/api/list/details/${list.id}`, {
      method: "PUT",
      body: JSON.stringify({
        name: name,
      } as EditListSchemaType),
    }).then((res) => {
      if (res.ok) {
        if (setListName) {
          setListName(name);
          setOpen(false);
        } else {
          window?.location?.reload();
        }
      } else {
        toast({
          title: "Something Went Wrong",
          description: "Please try again later.",
          variant: "destructive",
        });
      }
      setIsEditing(false);
    });
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          size={"icon"}
          variant={"outline"}
          className="text-text-secondary my-auto"
        >
          <PenSquareIcon />
        </Button>
      </DialogTrigger>
      <CompactDialogContent>
        <CompactDialogHeader>
          <DialogTitle>
            Edit List Name for <strong>{list.name}</strong>
          </DialogTitle>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            onKeyDownCapture={(e) => {
              if (e.key === "Enter") {
                handleEdit();
              }
            }}
          />
        </CompactDialogHeader>
        <Separator />
        <CompactDialogFooter>
          <Button onClick={handleEdit} variant={isDirty ? "brand" : "default"}>
            {isEditing ? <LoadingIcon /> : "Save"}
          </Button>
        </CompactDialogFooter>
      </CompactDialogContent>
    </Dialog>
  );
}

export function EditListDescriptionButton({ list }: { list: List }) {
  const [description, setDescription] = useState(list.description ?? "");
  const [isEditing, setIsEditing] = useState(false);

  function handleEdit() {
    if (isEditing) return;
    setIsEditing(true);

    void fetch(`/api/list/details/${list.id}`, {
      method: "PUT",
      body: JSON.stringify({
        description: description,
      } as EditListSchemaType),
    }).then((res) => {
      if (res.ok) {
        window?.location?.reload();
      } else {
        toast({
          title: "Something Went Wrong",
          description: "Please try again later.",
          variant: "destructive",
        });
      }
      setIsEditing(false);
    });
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          size={"icon"}
          variant={"outline"}
          className="text-text-secondary my-auto"
        >
          <PenSquareIcon />
        </Button>
      </DialogTrigger>
      <CompactDialogContent>
        <CompactDialogHeader>
          <DialogTitle>
            Edit List Description For <strong>{list.name}</strong>
          </DialogTitle>
          <Textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </CompactDialogHeader>
        <Separator />
        <CompactDialogFooter>
          <Button onClick={handleEdit}>
            {isEditing ? <LoadingIcon /> : "Edit List Description"}
          </Button>
        </CompactDialogFooter>
      </CompactDialogContent>
    </Dialog>
  );
}
